import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";


@Injectable()
export class GsService {
    url: any = environment.API_URL;


    constructor(
        private http: HttpClient
    ) {

    }

    createFai(data: any) {
        return this.http.post(this.url + 'event/create', data);
    }

    getFai(data: any) {
        return this.http.post(this.url + 'event/list', data);
    }

    updateFai(data: any) {
        return this.http.patch(this.url + 'event/update', data);
    }

    deleteFai(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({}), body: data
        };
        console.log('data', data);
        return this.http.delete(this.url + 'event/delete', httpOptions);
    }
}