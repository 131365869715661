import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";

@Injectable()
export class CareerService {
    url: any = environment.API_URL

    constructor(
        private http: HttpClient
    ) { }

    getCareerList(data: any) {
        return this.http.post(this.url + 'career/list', data);
    }

    deleteCareer(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({}), body: data
        };
        console.log('data', data);
        return this.http.delete(this.url + 'career/delete', httpOptions);
    }

    createCareer(data: any) {
        return this.http.post(this.url + 'career/create', data);
    }

    updateCareer(data: any) {
        return this.http.patch(this.url + 'career/update', data);
    }
}