import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { Resource } from '../models/resource.mode';
@Injectable()
export class ResourceService {

    url: any = environment.API_URL;


    constructor(
        private http: HttpClient
    ) {

    }

    createResource(res: Resource) {
        return this.http.post(this.url + 'event/create', res);
    }

    getResources(data: any) {
        return this.http.post(this.url + 'event/list', data);
    }

    updateResource(res: Resource) {
        return this.http.patch(this.url + 'event/update', res);
    }

    deleteResource(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({}), body: data
        };
        console.log('data', data);
        return this.http.delete(this.url + 'event/delete', httpOptions);
    }
}