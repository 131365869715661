import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  constructor() { }

  welcomeUserMsg = new BehaviorSubject(false);
  eventHeading = new BehaviorSubject(false);
  annualEventHeading = new BehaviorSubject(false);
  subEventHeading = new BehaviorSubject(false);
  careersHeading = new BehaviorSubject(false);
  sectorHeading = new BehaviorSubject(false);
  profileHeading = new BehaviorSubject(false);
  resourcesHeading = new BehaviorSubject(false);
  newsHeading = new BehaviorSubject(false);
  educationHeading = new BehaviorSubject(false);
  financialHeading = new BehaviorSubject(false);
  quizHeading = new BehaviorSubject(false);
  usersHeading = new BehaviorSubject(false);
  feedbackHeading = new BehaviorSubject(false);
  partnerHeading = new BehaviorSubject(false);
  notificationHeading = new BehaviorSubject(false);
  divisionHeading = new BehaviorSubject(false);
  subDivisionHeading = new BehaviorSubject(false);
  sponsorsHeading = new BehaviorSubject(false);

}
