import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { News } from '../models/news.model';
@Injectable()
export class NewsService {

    url: any = environment.API_URL;


    constructor(
        private http: HttpClient
    ) {

    }

    createNews(news: News) {
        return this.http.post(this.url + 'event/create', news);
    }

    getNews(data: any) {
        return this.http.post(this.url + 'event/list', data);
    }

    updateNews(news: News) {
        return this.http.patch(this.url + 'event/update', news);
    }

    deleteNews(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({}), body: data
        };
        console.log('data', data);
        return this.http.delete(this.url + 'event/delete', httpOptions);
    }
}