import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()
export class EducationService {
    url: any = environment.API_URL

    constructor(
        private http: HttpClient
    ) { }

    getEducationList(data: any) {
        return this.http.post(this.url + 'job/list', data);
    }

    deleteEducation(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({}), body: data
        };
        console.log('data', data);
        return this.http.delete(this.url + 'job/delete', httpOptions);
    }

    createEducation(data: any) {
        return this.http.post(this.url + 'job/create', data);
    }

    updateEducation(data: any) {
        return this.http.patch(this.url + 'job/update', data);
    }
}