import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()
export class DashboardService {
    url: any = environment.API_URL;
    lang = sessionStorage.getItem('lang');

    header = {};

    constructor(
        private http: HttpClient
    ) { }

    getDashboardData(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({ lang: this.lang ? this.lang : 'eng', 'Content-Type': 'application / json' })
        };
        return this.http.post(this.url + 'user/admin/dashboard', data);
    }

}