import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";
// import { Event } from '../models/event.model';

@Injectable()
export class AnnualEventService {

  url: any = environment.API_URL;

  constructor(
    private http: HttpClient
  ) { }


  createEvent(event: any) {
    return this.http.post(this.url + 'event/create', event);
  }

  getEvents(data: any) {
    return this.http.post(this.url + 'event/list', data);
  }

  updateEvent(event: any) {
    return this.http.patch(this.url + 'event/update', event);
  }

  deleteEvent(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({}), body: data
    };
    console.log('data', data);
    return this.http.delete(this.url + 'event/delete', httpOptions);
  }

   updateStatus(data: any) {
    return this.http.post(this.url + 'event/update-annual-status', data);
  }

}
