import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class SubEventService {

  url: any = environment.API_URL;

  constructor(
    private http: HttpClient
  ) {

  }

  getSponsor(data: any) {
    return this.http.post(this.url + 'event/list', data);
  }

  createEvent(event: any) {
    return this.http.post(this.url + 'event/create', event);
  }

  getEvents(data: any) {
    return this.http.post(this.url + 'event/list', data);
  }

  updateEvent(event: any) {
    return this.http.patch(this.url + 'event/update', event);
  }
}
