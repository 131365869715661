<div class="sidebar">
    <div class="logo"><img routerLink="/dashboard" src="../../../assets/icons/logo.svg" alt="logo"></div>
    <a routerLink="/dashboard" routerLinkActive="active">
        <span class="sidebar-icon">
            <img class="black-icon" src="../../../assets/icons/dashboard.svg" alt="">
            <img class="white-icon" src="../../../assets/icons/sidebar/dashboard.svg" alt="">
        </span>
        Dashboard</a>
    <a routerLink="/events" routerLinkActive="active">
        <span class="sidebar-icon">
            <img class="black-icon" src="../../../assets/icons/events.svg" alt="">
            <img class="white-icon" src="../../../assets/icons/sidebar/events.svg" alt="">
        </span>
        Events</a>

    <a routerLink="/annual-events" routerLinkActive="active">
        <span class="sidebar-icon">
            <img class="black-icon" src="../../../assets/icons/events.svg" alt="">
            <img class="white-icon" src="../../../assets/icons/sidebar/events.svg" alt="">
        </span>
        Annual Events</a>
    <a routerLink="/sub-events" routerLinkActive="active">
        <span class="sidebar-icon">
            <img class="black-icon" src="../../../assets/icons/events.svg" alt="">
            <img class="white-icon" src="../../../assets/icons/sidebar/events.svg" alt="">
        </span>
        Sub Events</a>
    <a routerLink="/divisions" routerLinkActive="active">
        <span class="sidebar-icon">
            <img class="black-icon" src="../../../assets/icons/events.svg" alt="">
            <img class="white-icon" src="../../../assets/icons/sidebar/events.svg" alt="">
        </span>
        Divisions</a>
    <a routerLink="/sub-divisions" routerLinkActive="active">
        <span class="sidebar-icon">
            <img class="black-icon" src="../../../assets/icons/events.svg" alt="">
            <img class="white-icon" src="../../../assets/icons/sidebar/events.svg" alt="">
        </span>
        Sub divisions</a>
    <a routerLink="/sponsors" routerLinkActive="active">
        <span class="sidebar-icon">
            <img class="black-icon" src="../../../assets/icons/events.svg" alt="">
            <img class="white-icon" src="../../../assets/icons/sidebar/events.svg" alt="">
        </span>
        Sponsors</a>
    <a routerLink="/careers" routerLinkActive="active">
        <span class="sidebar-icon">
            <img class="black-icon" src="../../../assets/icons/careers.svg" alt="">
            <img class="white-icon" src="../../../assets/icons/sidebar/careers.svg" alt="">
        </span>
        Career Profiles</a>
    <a routerLink="/sectors" routerLinkActive="active">
        <span class="sidebar-icon">
            <img class="black-icon" src="../../../assets/icons/sectors.svg" alt="">
            <img class="white-icon" src="../../../assets/icons/sidebar/sectors.svg" alt="">
        </span>
        Sectors</a>
    <a routerLink="/job-profile" routerLinkActive="active">
        <span class="sidebar-icon">
            <img class="black-icon" src="../../../assets/icons/sectors.svg" alt="">
            <img class="white-icon" src="../../../assets/icons/sidebar/sectors.svg" alt="">
        </span>
        Job profile / Trade</a>
    <a routerLink="/news" routerLinkActive="active">
        <span class="sidebar-icon">
            <img class="black-icon" src="../../../assets/icons/news.svg" alt="">
            <img class="white-icon" src="../../../assets/icons/sidebar/news.svg" alt="">
        </span> News</a>
    <a routerLink="/resources" routerLinkActive="active">
        <span class="sidebar-icon">
            <img class="black-icon" src="../../../assets/icons/resources.svg" alt="">
            <img class="white-icon" src="../../../assets/icons/sidebar/resources.svg" alt="">
        </span>
        Resources</a>
    <a routerLink="/education" routerLinkActive="active">
        <span class="sidebar-icon">
            <img class="black-icon" src="../../../assets/icons/gs-afi.svg" alt="">
            <img class="white-icon" src="../../../assets/icons/sidebar/gs-afi.svg" alt="">
        </span>
        Education
        Values</a>
    <a routerLink="/opportunities" routerLinkActive="active">
        <span class="sidebar-icon">
            <img class="black-icon" src="../../../assets/icons/financial-icon.svg" alt="">
            <img class="white-icon" src="../../../assets/icons/sidebar/financial-icon.svg" alt="">
        </span> Opportunities & Scholarships</a>
    <a routerLink="/quiz" routerLinkActive="active">
        <span class="sidebar-icon"><img class="black-icon" src="../../../assets/icons/quiz.svg" alt="">
            <img class="white-icon" src="../../../assets/icons/sidebar/quiz.svg" alt="">
        </span> Quiz</a>
    <a routerLink="/users" routerLinkActive="active">
        <span class="sidebar-icon"><img class="black-icon" src="../../../assets/icons/users.svg" alt="">
            <img class="white-icon" src="../../../assets/icons/sidebar/users.svg" alt="">
        </span> Users</a>
    <a routerLink="/feedback" routerLinkActive="active">
        <span class="sidebar-icon"><img class="black-icon" src="../../../assets/icons/feedback.svg" alt="">
            <img class="white-icon" src="../../../assets/icons/sidebar/feedback.svg" alt="">
        </span>
        Feedback</a>
    <a routerLink="/partners" routerLinkActive="active">
        <span class="sidebar-icon"><img class="black-icon" src="../../../assets/icons/partners.svg" alt="">
            <img class="white-icon" src="../../../assets/icons/sidebar/partners.svg" alt="">
        </span>
        Partners</a>
    <a routerLink="/notifications" routerLinkActive="active">
        <span class="sidebar-icon"><img class="black-icon" src="../../../assets/icons/notifications.svg" alt="">
            <img class="white-icon" src="../../../assets/icons/sidebar/notifications.svg" alt="">
        </span>
        Notifications</a>
</div>
<div class="sidebar">
    <div class="logo"><img routerLink="/dashboard" src="../../../assets/icons/logo.svg" alt="logo"></div>
    <a routerLink="/dashboard" routerLinkActive="active">
        <span class="sidebar-icon">
            <img class="black-icon" src="../../../assets/icons/dashboard.svg" alt="">
            <img class="white-icon" src="../../../assets/icons/sidebar/dashboard.svg" alt="">
        </span>
        Dashboard</a>
    <a routerLink="/events" routerLinkActive="active">
        <span class="sidebar-icon">
            <img class="black-icon" src="../../../assets/icons/events.svg" alt="">
            <img class="white-icon" src="../../../assets/icons/sidebar/events.svg" alt="">
        </span>
        Events</a>
    <a routerLink="/annual-events" routerLinkActive="active">
        <span class="sidebar-icon">
            <img class="black-icon" src="../../../assets/icons/annual-events.svg" alt="">
            <img class="white-icon" src="../../../assets/icons/sidebar/annual-events.svg" alt="">
        </span>
        Annual Events</a>
    <a routerLink="/sub-events" routerLinkActive="active">
        <span class="sidebar-icon">
            <img class="black-icon" src="../../../assets/icons/sub-events.svg" alt="">
            <img class="white-icon" src="../../../assets/icons/sidebar/sub-events.svg" alt="">
        </span>
        Sub Events</a>
    <a routerLink="/divisions" routerLinkActive="active">
        <span class="sidebar-icon">
            <img class="black-icon" src="../../../assets/icons/division.svg" alt="">
            <img class="white-icon" src="../../../assets/icons/sidebar/division.svg" alt="">
        </span>
        Divisions</a>
    <a routerLink="/sub-divisions" routerLinkActive="active">
        <span class="sidebar-icon">
            <img class="black-icon" src="../../../assets/icons/sub-division.svg" alt="">
            <img class="white-icon" src="../../../assets/icons/sidebar/sub-division.svg" alt="">
        </span>
        Sub divisions</a>
    <a routerLink="/sponsors" routerLinkActive="active">
        <span class="sidebar-icon">
            <img class="black-icon" src="../../../assets/icons/sponsor.svg" alt="">
            <img class="white-icon" src="../../../assets/icons/sidebar/sponsor.svg" alt="">
        </span>
        Sponsors</a>
    <a routerLink="/careers" routerLinkActive="active">
        <span class="sidebar-icon">
            <img class="black-icon" src="../../../assets/icons/careers.svg" alt="">
            <img class="white-icon" src="../../../assets/icons/sidebar/careers.svg" alt="">
        </span>
        Career Profiles</a>
    <a routerLink="/sectors" routerLinkActive="active">
        <span class="sidebar-icon">
            <img class="black-icon" src="../../../assets/icons/sectors.svg" alt="">
            <img class="white-icon" src="../../../assets/icons/sidebar/sectors.svg" alt="">
        </span>
        Sectors</a>
    <a routerLink="/job-profile" routerLinkActive="active">
        <span class="sidebar-icon">
            <img class="black-icon" src="../../../assets/icons/sectors.svg" alt="">
            <img class="white-icon" src="../../../assets/icons/sidebar/sectors.svg" alt="">
        </span>
        Job profile / Trade</a>
    <a routerLink="/news" routerLinkActive="active">
        <span class="sidebar-icon">
            <img class="black-icon" src="../../../assets/icons/news.svg" alt="">
            <img class="white-icon" src="../../../assets/icons/sidebar/news.svg" alt="">
        </span> News</a>
    <a routerLink="/resources" routerLinkActive="active">
        <span class="sidebar-icon">
            <img class="black-icon" src="../../../assets/icons/resources.svg" alt="">
            <img class="white-icon" src="../../../assets/icons/sidebar/resources.svg" alt="">
        </span>
        Resources</a>
    <a routerLink="/education" routerLinkActive="active">
        <span class="sidebar-icon">
            <img class="black-icon" src="../../../assets/icons/gs-afi.svg" alt="">
            <img class="white-icon" src="../../../assets/icons/sidebar/gs-afi.svg" alt="">
        </span>
        Education
        Values</a>
    <a routerLink="/opportunities" routerLinkActive="active">
        <span class="sidebar-icon">
            <img class="black-icon" src="../../../assets/icons/financial-icon.svg" alt="">
            <img class="white-icon" src="../../../assets/icons/sidebar/financial-icon.svg" alt="">
        </span> <span>Opportunities & Scholarships</span> </a>
    <a routerLink="/quiz" routerLinkActive="active">
        <span class="sidebar-icon"><img class="black-icon" src="../../../assets/icons/quiz.svg" alt="">
            <img class="white-icon" src="../../../assets/icons/sidebar/quiz.svg" alt="">
        </span> Quiz</a>
    <a routerLink="/users/list" routerLinkActive="active">
        <span class="sidebar-icon"><img class="black-icon" src="../../../assets/icons/users.svg" alt="">
            <img class="white-icon" src="../../../assets/icons/sidebar/users.svg" alt="">
        </span> Users</a>
    <a routerLink="/feedback" routerLinkActive="active">
        <span class="sidebar-icon"><img class="black-icon" src="../../../assets/icons/feedback.svg" alt="">
            <img class="white-icon" src="../../../assets/icons/sidebar/feedback.svg" alt="">
        </span>
        Feedback</a>
    <a routerLink="/partners" routerLinkActive="active">
        <span class="sidebar-icon"><img class="black-icon" src="../../../assets/icons/partners.svg" alt="">
            <img class="white-icon" src="../../../assets/icons/sidebar/partners.svg" alt="">
        </span>
        Partners</a>
    <a routerLink="/notifications" routerLinkActive="active">
        <span class="sidebar-icon"><img class="black-icon" src="../../../assets/icons/notifications.svg" alt="">
            <img class="white-icon" src="../../../assets/icons/sidebar/notifications.svg" alt="">
        </span>
        Notifications</a>
</div>


<!-- <div class="sidebar">
    <div class="logo"><img routerLink="/dashboard" src="../../../assets/icons/logo.svg" alt="logo"></div>
    <a routerLink="/dashboard" routerLinkActive="active"> <img src="../../../assets/icons/dashboard.svg"
            alt="dashboard">
        Dashboard</a>
    <a routerLink="/events/list" routerLinkActive="active"> <img src="../../../assets/icons/events.svg" alt="">
        Events</a>
    <a routerLink="/careers/list" routerLinkActive="active"> <img src="../../../assets/icons/careers.svg" alt="">
        Career Profiles</a>
    <a routerLink="/sectors" routerLinkActive="active"> <img src="../../../assets/icons/sectors.svg" alt="">
        Sectors</a>
    <a routerLink="/job-profile/list" routerLinkActive="active"> <img src="../../../assets/icons/sectors.svg" alt="">
        Job profile / Trade</a>
    <a routerLink="/news/list" routerLinkActive="active"> <img src="../../../assets/icons/news.svg" alt=""> News</a>
    <a routerLink="/resources/list" routerLinkActive="active"> <img src="../../../assets/icons/resources.svg" alt="">
        Resources</a>
    <a routerLink="/education/list" routerLinkActive="active"> <img src="../../../assets/icons/gs-afi.svg" alt="">
        Education
        Values</a>
    <a routerLink="/financial/list" routerLinkActive="active"> <img src="../../../assets/icons/financial-icon.svg"
            alt=""> Financial
        Aid Info</a>
    <a routerLink="/quiz/list" routerLinkActive="active"> <img src="../../../assets/icons/quiz.svg" alt=""> Quiz</a>
    <a routerLink="/users/list" routerLinkActive="active"> <img src="../../../assets/icons/users.svg" alt=""> Users</a>
    <a routerLink="/feedback/list" routerLinkActive="active"> <img src="../../../assets/icons/feedback.svg" alt="">
        Feedback</a>
    <a routerLink="/partners/list" routerLinkActive="active"> <img src="../../../assets/icons/partners.svg" alt="">
        Partners</a>
    <a routerLink="/notifications/list" routerLinkActive="active"> <img src="../../../assets/icons/notifications.svg"
            alt="">
        Notifications</a>
</div> -->