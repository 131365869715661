import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()

export class FeedbackService {

    url: any = environment.API_URL;


    constructor(
        private http: HttpClient
    ) {

    }

    getFeedbackLists(data: any) {
        return this.http.post(this.url + 'user/feedback/list', data);
    }


    deleteFeedback(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({}), body: data
        };
        return this.http.delete(this.url + 'user/feedback/delete', httpOptions)
    }

    sendReply(data: any) {
        return this.http.post(this.url + 'user/feedback/reply', data);
    }


}