import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class SubCategoriesService {

  url: any = environment.API_URL;
  header = { "lang": "eng" };


  constructor(
    private http: HttpClient
  ) {

  }

  getCategory(data: any) {
    return this.http.post(this.url + 'event/list', data);
  }

  saveCategory(data: any) {
    return this.http.post(this.url + 'event/create', data);
  }

  updateCategory(data: any) {
    return this.http.patch(this.url + 'event/update', data)
  }

  deleteCategory(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({}), body: data
    };
    return this.http.delete(this.url + 'event/delete', httpOptions)
  }
}
