import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { Event } from '../models/event.model';

@Injectable()
export class EventService {

    url: any = environment.API_URL;


    constructor(
        private http: HttpClient
    ) {

    }

    getCategories(data: any) {
        return this.http.post(this.url + 'event/list', data);
    }

    createEvent(event: Event) {
        return this.http.post(this.url + 'event/create', event);
    }

    getEvents(data: any) {
        return this.http.post(this.url + 'event/list', data);
    }

    updateEvent(event: Event) {
        return this.http.patch(this.url + 'event/update', event);
    }

    deleteEvent(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({}), body: data
        };
        console.log('data', data);
        return this.http.delete(this.url + 'event/delete', httpOptions);
    }
}