import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()

export class JobProfileService {

    url: any = environment.API_URL;


    constructor(
        private http: HttpClient
    ) {

    }

    getJobProfile(data: any) {
        return this.http.post(this.url + 'job/list', data);
    }

    saveJobProfile(data: any) {
        return this.http.post(this.url + 'job/create', data);
    }

    updateJobProfile(data: any) {
        return this.http.patch(this.url + 'job/update', data)
    }

    deleteJobProfile(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({}), body: data
        };
        return this.http.delete(this.url + 'job/delete', httpOptions)
    }


}