import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { error } from 'src/app/_helpers/error';
import { User } from '../../models/user.model';
import { AuthService } from '../../services/auth.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  submitted: boolean = false;
  error = error.err;
  hide = true;

  constructor(
    private authService: AuthService,
    private router: Router,
    private toastr: ToastrService
  ) { }

  loginForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required, Validators.minLength(6)])
  })

  ngOnInit() {
  }
  myFunction() {
    this.hide = !this.hide;
  }


  get f() {
    return this.loginForm.controls;
  }

  onCopy(event: ClipboardEvent): void {
    event.preventDefault();
    alert('Copying is not allowed in this field.');
  }

  // Method to handle the paste event and prevent it
  onPaste(event: ClipboardEvent): void {
    event.preventDefault();
    alert('Pasting is not allowed in this field.');
  }

  login() {
    this.submitted = true;
    if (this.loginForm.invalid || this.loginForm.pristine) return;
    this.submitted = false;
    let user: User = {
      email: this.loginForm.value.email,
      password: this.loginForm.value.password,
      deviceType: "web",
      userType: 1,
      deviceId: 'GGJHH-KJKJK-KKKKL-KJKJ',
      fcmToken: 'SJKSAJKSALKKJDKKJD'
    }

    // localStorage.removeItem('currentUser');
    // sessionStorage.removeItem('lang');
    this.authService.logout();

    this.authService.login(user)
      .subscribe((res: any) => {
        if (res.status == true) {
          localStorage.setItem('currentUser', JSON.stringify(res.result));
          localStorage.setItem('lang', 'eng');
          this.router.navigateByUrl('/dashboard');
        } else {
          this.toastr.error(res.message);
        }
      },
        err => {
          this.toastr.error(this.error);
        }
      )
  }

}
