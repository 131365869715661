import { Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { HeaderService } from '../services/header.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  welcomeUserMsg: boolean = false;
  eventsHeading: boolean = false;
  careersHeading: boolean = false;
  sectorHeading: boolean = false;
  profileHeading: boolean = false;
  resourcesHeading: boolean = false;
  newsHeading: boolean = false;
  educationHeading: boolean = false;
  financialHeading: boolean = false;
  quizHeading: boolean = false;
  usersHeading: boolean = false;
  feedbackHeading: boolean = false;
  partnerHeading: boolean = false;
  notificationHeading: boolean = false;
  divisionHeading: boolean = false;
  subDivisionHeading: boolean = false;
  sponsorsHeading: boolean = false;
  annualEventHeading: boolean = false;
  subEventHeading: boolean = false;

  default: boolean;
  lang: string;

  private returnUrl: any;

  profilePic: String = '';
  modalRef: any;

  constructor(
    private _header: HeaderService,
    public router: Router,
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private toastr: ToastrService
  ) {
    this._header.welcomeUserMsg.subscribe((res) => {
      this.welcomeUserMsg = res;
    });
    this._header.eventHeading.subscribe((res) => {
      this.eventsHeading = res;
    });
    this._header.careersHeading.subscribe((res) => {
      this.careersHeading = res;
    });
    this._header.sectorHeading.subscribe((res) => {
      this.sectorHeading = res;
    });
    this._header.profileHeading.subscribe((res) => {
      this.profileHeading = res;
    });
    this._header.resourcesHeading.subscribe((res) => {
      this.resourcesHeading = res;
    });
    this._header.newsHeading.subscribe((res) => {
      this.newsHeading = res;
    });
    this._header.educationHeading.subscribe((res) => {
      this.educationHeading = res;
    });
    this._header.financialHeading.subscribe((res) => {
      this.financialHeading = res;
    });
    this._header.quizHeading.subscribe((res) => {
      this.quizHeading = res;
    });
    this._header.usersHeading.subscribe((res) => {
      this.usersHeading = res;
    });
    this._header.feedbackHeading.subscribe((res) => {
      this.feedbackHeading = res;
    });
    this._header.partnerHeading.subscribe((res) => {
      this.partnerHeading = res;
    });
    this._header.notificationHeading.subscribe((res) => {
      this.notificationHeading = res;
    });
    this._header.divisionHeading.subscribe((res) => {
      this.divisionHeading = res;
    });
    this._header.subDivisionHeading.subscribe((res) => {
      this.subDivisionHeading = res;
    });
    this._header.sponsorsHeading.subscribe((res) => {
      this.sponsorsHeading = res;
    });
    this._header.annualEventHeading.subscribe((res) => {
      this.annualEventHeading = res;
    });
    this._header.subEventHeading.subscribe((res) => {
      this.subEventHeading = res;
    });
  }

  userName: String;

  ngOnInit(): void {
    let user = JSON.parse(localStorage.getItem('currentUser'));
    let language = localStorage.getItem('lang');
    this.default = language ? (language == 'eng' ? true : false) : true;
    this.lang = language ? (language == 'eng' ? 'English' : 'Français') : 'English';
    this.userName = user.fname;
    this.profilePic = user.profileImg ? user.profileImg : '../../../assets/icons/user-img.svg';

    // redirect back to the returnUrl before login
    // this.route.queryParams.subscribe(params => {
    //   console.log('params', params);
    //   this.returnUrl = params.returnUrl || '/';
    // });
  }

  // logout() {
  //   localStorage.removeItem('currentUser');
  //   localStorage.removeItem('lang');
  //   this.modalRef.hide();
  //   this.router.navigateByUrl('/auth/login')
  // }

  logout() {
    let userId = JSON.parse(localStorage.getItem('currentUser'));
    this.authService.logOutuser({ userId: userId._id })
      .subscribe((res: any) => {
        if (res.status == true) {
          localStorage.removeItem('currentUser');
          localStorage.removeItem('lang');
          this.modalRef.hide();
          this.router.navigateByUrl('/auth/login')
        } else {
          this.toastr.error(res.message);
        }
      },
        err => {
          this.toastr.error(err.message);
        }
      )
  }

  // modal lougout confirmation
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
  confirm() {
    this.logout();
  }
  cancel() {
    this.modalRef.hide();
  }

  englishSelection() {
    this.default = true;
    this.lang = 'English';
    localStorage.setItem('lang', 'eng');
    window.location.reload();
    // console.log('this.returnUrl', this.returnUrl);
    // this.router.navigateByUrl(this.returnUrl); // Main page
  }

  frenchSelection() {
    this.default = false;
    this.lang = 'Français';
    localStorage.setItem('lang', 'fre');
    window.location.reload();
    // console.log('this.rturnUrl', this.returnUrl);
    // this.router.navigateByUrl(this.returnUrl); // Main page
  }

}
