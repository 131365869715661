import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './auth/components/login/login.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { PolicyComponent } from './views/policy/policy.component';
import { TermsComponent } from './views/terms/terms.component';
import { ViewsComponent } from './views/views.component';
import { AuthGuard } from './_helpers/auth.guard.service';


const routes: Routes = [

  { path: 'terms-conditions', component: TermsComponent },
  { path: 'privacy-policy', component: PolicyComponent },


  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: '',
    component: ViewsComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'edit-profile', component: EditProfileComponent },
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule)
      },
      {
        path: 'events',
        loadChildren: () =>
          import('./views/events/events.module').then((m) => m.EventsModule),

      },
      {
        path: 'annual-events',
        loadChildren: () =>
          import('./views/annual-event/annual-event.module').then((m) => m.AnnualEventModule),

      },
      {
        path: 'sub-events',
        loadChildren: () =>
          import('./views/sub-event/sub-event.module').then((m) => m.SubEventModule),

      },
      {
        path: 'careers',
        loadChildren: () =>
          import('./views/careers/career.module').then((m) => m.CareerModule),

      },
      {
        path: 'sectors',
        loadChildren: () =>
          import('./views/sectors/sectors.module').then((m) => m.SectorsModule),

      },
      {
        path: 'divisions',
        loadChildren: () =>
          import('./views/categories/categories.module').then((m) => m.CategoriesModule),

      },
      {
        path: 'sub-divisions',
        loadChildren: () =>
          import('./views/sub-categories/sub-categories.module').then((m) => m.SubCategoriesModule),

      },
      {
        path: 'sponsors',
        loadChildren: () =>
          import('./views/sponsors/sponsors.module').then((m) => m.SponsorsModule),

      },
      {
        path: 'job-profile',
        loadChildren: () =>
          import('./views/job-profile/job-profile.module').then(
            (m) => m.CareerProfileModule
          ),

      },
      {
        path: 'news',
        loadChildren: () =>
          import('./views/news/news.module').then((m) => m.NewsModule),

      },
      {
        path: 'resources',
        loadChildren: () =>
          import('./views/resources/resources.module').then(
            (m) => m.ResourcesModule
          ),

      },
      {
        path: 'education',
        loadChildren: () =>
          import('./views/education/education.module').then(
            (m) => m.EducationModule
          ),

      },
      {
        path: 'opportunities',
        loadChildren: () => import('./views/gs/gs.module').then((m) => m.GsModule),

      },
      {
        path: 'quiz',
        loadChildren: () =>
          import('./views/quiz/quiz.module').then((m) => m.QuizModule),

      },
      {
        path: 'users',
        loadChildren: () =>
          import('./views/users/users.module').then((m) => m.UsersModule),

      },
      {
        path: 'feedback',
        loadChildren: () =>
          import('./views/feedback/feedback.module').then((m) => m.FeedbackModule),

      },
      {
        path: 'partners',
        loadChildren: () =>
          import('./views/partners/partners.module').then((m) => m.PartnersModule),

      },
      {
        path: 'notifications',
        loadChildren: () =>
          import('./views/notifications/notifications.module').then(
            (m) => m.NotificationsModule
          ),

      },

      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: '**', redirectTo: 'dashboard', pathMatch: 'full' },
    ]
  },
  // { path: '', redirectTo: 'auth', pathMatch: 'full' },
  // { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
