import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()

export class SectorService {

    url: any = environment.API_URL;
    header = { "lang": "eng" };


    constructor(
        private http: HttpClient
    ) {

    }

    getSector(data: any) {
        return this.http.post(this.url + 'job/list', data);
    }

    saveSector(data: any) {
        return this.http.post(this.url + 'job/create', data);
    }

    updateSector(data: any) {
        return this.http.patch(this.url + 'job/update', data)
    }

    deleteSector(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({}), body: data
        };
        return this.http.delete(this.url + 'job/delete', httpOptions)
    }
}