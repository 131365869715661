import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { HeaderService } from 'src/app/shared/services/header.service';
import { SubCategoriesService } from '../../services/sub-categories.service';

@Component({
  selector: 'app-sub-categories',
  templateUrl: './sub-categories.component.html',
  styleUrls: ['./sub-categories.component.scss']
})
export class SubCategoriesComponent implements OnInit {
  modalRef: BsModalRef | null;
  modalRef2: BsModalRef;
  modalRef3: BsModalRef;
  deleteButton: boolean = false;
  cancelButton: boolean = false;
  createButton: boolean = false;
  confirmButton: boolean = false;
  saveButton: boolean = false;
  editButton: boolean = false;
  deleteImg: boolean = false;
  deleteHead: boolean = false;
  sectorForm: boolean = false;
  sectorHead: boolean = false;
  createHead: boolean = false;
  submitted: boolean = false;

  clicked: boolean = false;

  id: string;
  sector: string;

  update: boolean = true;

  @ViewChild('template') template: TemplateRef<any>;
  @ViewChild('templateNested') templateNested: TemplateRef<any>;

  catForm = new FormGroup({
    catTitle: new FormControl('', Validators.required),
    // eventCategory: new FormControl('', Validators.required),
  })

  get f() {
    return this.catForm.controls;
  }

  constructor(
    private _header: HeaderService,
    private modalService: BsModalService,
    private categoriesService: SubCategoriesService,
    private toastrService: ToastrService
  ) { }

  lang: string = 'eng';
  // heading
  ngOnInit(): void {
    this._header.subDivisionHeading.next(true);
    this.getCategoryLists();
    this.lang = localStorage.getItem('lang');
  }
  ngOnDestroy() {
    this._header.subDivisionHeading.next(false);
  }

  categories: any;

  // modal
  viewCareerProfile(template: TemplateRef<any>, s: any) {
    this.id = s._id;
    this.category = s;
    this.catForm.reset();
    let data = { "eType": "subDivision", "eventId": this.id, "search": "", "page": "1", "pageLimit": "100" }
    this.categoriesService.getCategory(data).subscribe((res: any) => {
      console.log('res', res);
      this.catForm.setValue({
        catTitle: res.result[0].subDivisionName,
        // eventCategory: res.result[0].categoryId
      });
    })
    this.catForm.get('catTitle').disable();
    // this.catForm.get('eventCategory').disable();
    this.modalRef = this.modalService.show(template);
    this.deleteButton = true;
    this.editButton = true;
    this.sectorForm = true;
    this.sectorHead = true;
    this.cancelButton = false;
    this.createButton = false;
    this.confirmButton = false;
    this.saveButton = false;
    this.deleteHead = false;
    this.deleteImg = false;
    this.createHead = false;
  }
  category: any;
  editCareerProfile(template: TemplateRef<any>, s: any) {
    this.id = '';
    this.catForm.get('catTitle').enable();
    this.catForm.reset();
    this.id = this.id ? this.id : s._id;
    this.category = this.category ? this.category : s.subDivisionName;
    let data = { "eType": "subDivision", "eventId": this.id, "search": "", "page": "1", "pageLimit": "100" }
    this.categoriesService.getCategory(data).subscribe((res: any) => {
      console.log('res', res);
      this.catForm.setValue({
        catTitle: res.result[0].subDivisionName,
        // eventCategory: res.result[0].categoryId
      });
    })
    // this.catForm.setValue({
    //   catTitle: s.subDivisionName ? s.subDivisionName : this.category
    // })
    this.update = true;
    this.modalRef = this.modalService.show(template);
    this.cancelButton = true;
    this.saveButton = true;
    this.deleteButton = false;
    this.editButton = false;
    this.sectorHead = true;
    this.sectorForm = true;
    this.createButton = false;
    this.confirmButton = false;
    this.deleteHead = false;
    this.deleteImg = false;
    this.createHead = false;
  }
  deleteCareerProfile(template: TemplateRef<any>, s: any) {
    this.id = this.id ? this.id : s._id;
    this.modalRef = this.modalService.show(template);
    this.cancelButton = true;
    this.confirmButton = true;
    this.deleteHead = true;
    this.sectorHead = false;
    this.sectorForm = false;
    this.saveButton = false;
    this.deleteImg = true;
    this.editButton = false;
    this.createButton = false;
    this.createHead = false;
    this.deleteButton = false;

  }
  createProfileSector(template: TemplateRef<any>) {
    this.catForm.reset();
    this.catForm.get('catTitle').enable();
    // this.catForm.get('eventCategory').enable();  
    this.update = false;
    this.catForm.reset();
    this.modalRef = this.modalService.show(template);
    this.createHead = true;
    this.deleteImg = false;
    this.deleteHead = false;
    this.sectorForm = true;
    this.cancelButton = true;
    this.createButton = true;
    this.confirmButton = false;
    this.editButton = false;
    this.deleteButton = false;
    this.saveButton = false;
    this.sectorHead = false;
  }

  delete(): void {
    this.modalRef.hide();
    this.deleteCareerProfile(this.template, this.id)
  }
  cancel(): void {
    this.modalRef.hide();
  }

  confirm(): void {
    this.categoriesService.deleteCategory(
      { "eType": "subDivision", "eventId": this.id }
    ).subscribe((res: any) => {
      if (res.status == true) {
        this.getCategoryLists();
        this.modalRef.hide();
        this.id = '';
      } else {
        this.toastrService.error(res.message);
      }
    }, err => {
      this.toastrService.error('Unable to delete sector');
    })
  }
  save(): void {
    this.clicked = true;
    if (this.catForm.invalid) return;
    this.clicked = false;
    this.categoriesService.updateCategory(
      { "eType": "subDivision", "eventId": this.id, subDivisionName: this.catForm.value.catTitle }
    )
      .subscribe((res: any) => {
        if (res.status == true) {
          this.modalRef.hide();
          this.getCategoryLists();
          this.id = '';
        } else {
          this.toastrService.error(res.message);
        }
      }, err => {
        this.toastrService.error('Unable to update sector');
      })
  }

  edit(): void {
    this.modalRef.hide();
    this.editCareerProfile(this.template, this.category);
  }


  notFound: boolean = false;
  getCategoryLists() {
    let data = { "eType": "subDivision", "eventId": "", "search": "", "page": "1", "pageLimit": "100" }
    this.categoriesService.getCategory(data)
      .subscribe((res: any) => {
        // this.getEventCategories();
        this.categories = res.result;
        if (res.totalCount == 0) {
          this.notFound = true;
        } else {
          this.notFound = false;
        }
      })
  }

  eventCategories: any = [];
  getEventCategories() {
    let data = { "eType": "category", "eventId": "", "search": "", "page": "1", "pageLimit": "100" }
    this.categoriesService.getCategory(data)
      .subscribe((res: any) => {
        this.eventCategories = res.result;
        if (res.totalCount == 0) {
          // this.notFound = true;
        } else {
          // this.notFound = false;
        }
      })
  }

  saveCategories() {
    this.clicked = true;
    if (this.catForm.invalid || this.catForm.pristine) return;
    this.clicked = false;
    this.categoriesService.saveCategory(
      { "eType": "subDivision", subDivisionName: this.catForm.value.catTitle }
    )
      .subscribe((res: any) => {
        if (res.status == true) {
          this.modalRef.hide();
          this.getCategoryLists();
          this.id = '';
        } else {
          this.toastrService.error(res.message);
        }
      }, err => {
        this.toastrService.error('Unable to update sector');
      })
  }

  // nested modal
  openModal2(template: TemplateRef<any>) {
    this.modalRef2 = this.modalService.show(template, { id: 2, class: 'second' });
  }
  confirm1() {
    this.modalRef3.hide();
  }
}
