<app-layout></app-layout>
<div class="content">
    <div class="news-header">
        <button class="btn btn-create" (click)="createProfileSector(template)"> <img
                src="../../../../../assets/icons/plus-icon.svg" alt=""> Create Sub division</button>
    </div>
    <div class="news-list">
        <div class="news-card" *ngFor="let category of categories">
            <div>
                <h3>{{category.subDivisionName}}</h3>
                <div class="skill-icons">
                    <span (click)="viewCareerProfile(template, category)"><img
                            src="../../../../../assets/icons/view-icon.svg" alt=""></span>
                    <span (click)="editCareerProfile(template, category)"><img
                            src="../../../../../assets/icons/edit-icon.svg" alt=""></span>
                    <span (click)="deleteCareerProfile(template, category)"><img
                            src="../../../../../assets/icons/delete-icon.svg" alt=""></span>
                </div>
            </div>
        </div>
    </div>
    <h3 *ngIf="notFound" style="text-align: center;">No result found</h3>
</div>

<!-- delete modal -->
<ng-template #template>
    <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body text-center delete-modal">
        <h2 class="mt-4" *ngIf="sectorHead">Sub division</h2>
        <h2 class="mt-4" *ngIf="createHead">Create Sub division</h2>
        <img *ngIf="deleteImg" src="../../../../../assets/images/delete-img.svg" alt="">
        <p *ngIf="deleteHead">Are you sure you want to <br>delete this Sub Division?</p>
        <div class="profile-form" *ngIf="sectorForm">
            <form [formGroup]="catForm">
                <!-- <div class="form-group">
                    <label for="eventCategory">Select event category *</label>
                    <select name="Career Time" id="event-category" formControlName="eventCategory">
                        <option value="" selected data-default disabled>Select</option>
                        <option *ngFor="let e of eventCategories" [value]="e._id">
                            {{e.categoryName}}
                        </option>
                    </select>
                </div> -->
                <div class="form-group">
                    <label for="title">Sub Division Title *</label>
                    <input type="text" maxlength="30" class="form-control" placeholder="Title"
                        formControlName="catTitle">
                    <div class="alert alert-danger" *ngIf="clicked && f.catTitle.errors">
                        <span *ngIf="lang == 'eng'">Sub Category title is required.</span>
                        <span *ngIf="lang == 'fre'">Titre du secteur est requis.</span>
                    </div>
                </div>
            </form>
        </div>
        <div class="modal-btn">
            <!-- <button class="btn btn-cancel" (click)="delete()" *ngIf="deleteButton">Delete</button> -->
            <button class="btn btn-cancel" (click)="cancel()" *ngIf="cancelButton || editButton">Cancel</button>
            <button class="btn btn-blue" (click)="save()" *ngIf="saveButton">Save</button>
            <button class="btn btn-blue" (click)="edit()" *ngIf="editButton">Edit</button>
            <button class="btn btn-blue" (click)="saveCategories()" *ngIf="createButton">Create</button>
            <button class="btn btn-confirm" (click)="confirm()" *ngIf="confirmButton">Confirm</button>

        </div>
    </div>
</ng-template>

<!-- create fonfirm -->
<ng-template #templateNested he>
    <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef3.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body text-center delete-modal align-items-center">
        <img src="../../../../../assets/images/create-successfull-img.svg" alt="">
        <p>Category Created Successfully.</p>
        <div class="modal-btn d-flex justify-content-center">
            <button class="btn btn-blue" (click)="confirm1()">ok</button>
        </div>
    </div>
</ng-template>