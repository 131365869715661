import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  submitted: boolean = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private toastr: ToastrService

  ) { }

  resetForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email])
  })

  ngOnInit(): void {
  }

  get f() {
    return this.resetForm.controls;
  }

  resetPassword() {
    this.submitted = true;
    if (this.resetForm.invalid || this.resetForm.pristine) return;
    this.submitted = false;
    this.authService.reset(this.resetForm.value)
      .subscribe((res: any) => {
        if (res.status == true) {
          this.router.navigateByUrl('/auth/email-send');
        } else {
          this.toastr.error("Something Went Worng");
        }
      })
  }

}
