<div class="policy">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1>Terms of Use</h1>
                <p>Effective date: 11 Aug 2021</p>
                <p>PLEASE READ THIS TERMS OF USE AGREEMENT (THE “AGREEMENT”) CAREFULLY AS IT CONSTITUTES A LEGALLY BINDING AGREEMENT BETWEEN YOU AND SKILLS ONTARIO (SKILLS ONTARIO) AND GOVERNS YOUR USE OF THE “SKILLS ONTARIO APP & RESOURCES” OR (S.O.A.R.) (THE “APP”) AND ITS CONTENT AND SERVICES.</p>

                <h1>Acceptance of this Agreement</h1>
                <p>Skills Ontario provides an online career guidance tool for youths (ages 12-17), using web-based technology that connects users with careers in the skilled trades and technologies, by providing relevant information, resources, and initiatives. The App permits users to explore and discover professions in the skilled trades and technologies based on their skills, interests, and personality. Skills Ontario is not an employer of skilled trades and technologies. </p>
                <p>By downloading, installing, accessing, otherwise using the App, or any content, information, function or services available or enabled by Skills Ontario or the App, or completing the App’s account registration process, you and your heirs, successors, executors, administrators and assigns (collectively, “You” or “Your”) hereby agree to be bound by the terms and conditions of this Agreement, as may be amended from time to time. Further, You hereby represent and warrant that:</p>
                <p><b>You have reviewed and understand the terms and conditions of this Agreement;</b></p>
                <p><b>You are of legal age in the jurisdiction in which You reside to form a legally binding contract with Skills Ontario, or You are the parent or legal guardian of any person who is under the legal age in the jurisdiction in which they reside (a “Minor”) and who intends to use the App; and
                You have the authority to enter into this Agreement and, if applicable, on behalf of any Minor or organization.</b></p>
                <p><b>Except as otherwise provided in this Agreement, if You do not agree to be legally bound by this Agreement, You may not download, install, access, copy or otherwise use the App.</b></p>

                <h1>Modifications</h1>
                <p>Skills Ontario reserves the right to modify the terms and conditions of this Agreement or its policies relating to the App at any time, effective upon posting of an updated version of this Agreement through the App. You should regularly review this Agreement, as Your continued use of the App after any such changes constitute Your agreement to such changes. </p>

                <h1>Additional Terms and Policies</h1>
                <p>By using the App, You agree to be bound by this Agreement and acknowledge and agree to the collection, use and disclosure of your personal information in accordance Skills Ontario’s Privacy Policy which is incorporated into this Agreement by reference. Certain services or features of our App may be subject to additional terms and conditions, which to the extent permitted by applicable law are incorporated into this Agreement by reference.</p>

                <h1>No Employment or Engagement</h1>
                <h1>No Employment Relationship</h1>
                <p>You understand and agree that Skills Ontario provides a platform connecting You with careers in skilled trades and technologies and employers of skilled trades and technologies (“Employers”). You acknowledge and agree that Skills Ontario does not itself employ skilled trades or technologies and has no responsibility or liability for the acts or omissions of any Employer. You acknowledge that the App is not intended to create an employment relationship between You and Skills Ontario, and that no employment relationship will be created through Your use of the App. </p>

                <h1>No Contractor Relationship</h1>
                <p>You further acknowledge and agree that Skills Ontario does not itself engage or retain skilled trades or technologies. You acknowledge that the App is not intended to create an independent contractor or dependent contractor relationship between You and Skills Ontario, and that no independent contractor or dependent contractor relationship will be created through Your use of the App.</p>

                <h1>License Grant and Use Restrictions</h1>
                <p>Skills Ontario grants You a personal, revocable, non-exclusive, non-transferable, limited right to install and use the App on a device owned or controlled by You and to access and use the App strictly in accordance with the terms of this Agreement, the Privacy Policy, and all applicable laws.  You represent, warrant, and agree that you are using the App solely for Your own personal use and not for redistribution or transfer of any kind. </p>
                <p>You will not modify, adapt, or create any derivative work from the App.  You will not circumvent, disable, or tamper with any security-related components of the App. You will not copy, or distribute, sell, lease, rent, exchange, publish or make the App available to third parties or use the App to create a product, service or software that is directly or indirectly competitive with the App. </p>

                <h1>Disclaimer of Warranties</h1>
                <p>YOU EXPRESSLY UNDERSTAND AND AGREE THAT TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, YOUR USE OF THE APP IS ENTIRELY AT YOUR OWN RISK. CHANGES ARE PERIODICALLY MADE TO THE APP AND MAY BE MADE AT ANY TIME WITHOUT NOTICE TO YOU. THE APP IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS WITHOUT ANY REPRESENTATIONS, WARRANTIES OR CONDITIONS OF ANY KIND, EITHER EXPRESS, IMPLIED OR STATUTORY, INCLUDING, BUT NOT LIMITED TO, WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR OR GENERAL PURPOSE, TITLE AND NON-INFRINGEMENT OF THIRD PARTY RIGHTS. SKILLS ONTARIO MAKES NO WARRANTIES, CONDITIONS, OR REPRESENTATIONS THAT: (1) THE APP OR ITS CONTENT OR SERVICES WILL MEET YOUR REQUIREMENTS; (2) THE APP OR ITS CONTENT OR SERVICES WILL BE UNINTERRUPTED, ACCURATE, RELIABLE, COMPLETE, TIMELY OR SECURE; OR (3) ANY ERRORS IN THE APP OR SERVICES WILL BE CORRECTED.</p>
                <p>SKILLS ONTARIO DOES NOT WARRANT THAT THE APP WILL OPERATE ERROR-FREE OR THAT THE APP IS FREE OF COMPUTER VIRUSES AND OTHER HARMFUL MALWARE. IF YOUR USE OF THE APP RESULTS IN THE NEED FOR SERVICING OR REPLACING EQUIPMENT OR DATA, SKILLS ONTARIO WILL NOT BE RESPONSIBLE FOR THOSE ECONOMIC COSTS. </p>

                <h1>Limitation of Liability</h1>
                <div class="sub-policy">
                    <h3>General</h3>
                    <p>You understand and agree to the limitations on remedies and liabilities set forth herein to keep the App and its content and services simple, efficient, and free of charge for all users. </p>

                    <h3>Fees</h3>
                    <p>Your cellular phone provider’s data and messaging rates may apply when You use this App, including domestic and roaming charges, if applicable, and You agree that You are solely responsible for any cellular usage charges You incur by using the App.</p>

                    <h3>Limit on Liability</h3>
                    <p>TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, SKILLS ONTARIO’S AGGREGATE LIABILITY SHALL NOT EXCEED THE AMOUNT YOU PAID TO SKILLS ONTARIO TO USE THE APP, IF ANY. </p>

                    <h3>Disclaimer of Certain Damages</h3>
                    <p>TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, SKILLS ONTARIO SHALL NOT BE LIABLE TO YOU OR ANYONE ELSE FOR ANY ACCIDENTS, PROPERTY DAMAGE, PERSONAL INJURY, DEATH, OR FOR ANY DIRECT, INDIRECT, PUNITIVE, SPECIAL, EXEMPLARY, INCIDENTIAL OR CONSEQUENTIAL DAMAGES OF ANY NATURE OR KIND WHATSOEVER ARISING OUT OF OR IN CONNECTION WITH THE DISTRIBUTION OF, THE PERFORMANCE OR NON-PERFORMANCE OF, OR YOUR ACCESS OR USE OF OR INABILITY TO ACCESS OR USE THE APP OR ITS CONTENTS OR SERVICES, WHETHER OR NOT THE DAMAGES WERE FORESEEABLE AND OR NOT, AND WHETHER OR NOT SUCH PARTY WAS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. </p>

                    <h3>Indemnification</h3>
                    <p>You will indemnify, defend and hold harmless Skills Ontario from and against any claims, proceedings, losses, damages, fines, penalties, interest or expenses (including legal fees and other fees for professional advisors on a full indemnity basis) arising out of or in connection with: (1) Your breach of this Agreement; (2) Your violation of law; (3) Your negligent acts or omissions, or willful misconduct; or (4) Your violation of the rights of a third party. You will promptly notify Skills Ontario in writing of any third-party claim arising out of or in connection with Your access or use of the App or its content or services. </p>
                </div>

                <h1>Intellectual Property Rights</h1>
                <p>Skills Ontario, and its affiliates, licensors or suppliers, alone shall own all right, title and interest, including all related intellectual property rights, in and to the App, and its content and information, including, but not limited to, any text, photos, images, illustrations, videos, graphics, audio clips, guides, questionnaires and articles. You expressly acknowledge and agree that the App, and any copies thereof, and all copyrights, patents, trademarks, trade secrets and other intellectual property rights associated therewith, and any data collected by Skills Ontario through the App are, and shall remain, the property of Skills Ontario. Furthermore, You acknowledge and agree that the source and object code of the App and the format, directories, queries, algorithms, structure and organization of the App are the intellectual property and proprietary and confidential information of Skills Ontario and its affiliates, licensors or suppliers. </p>
                <p>This Agreement is not a sale and does not convey to You any property rights of ownership in or related to the App or its content, information or services, or any intellectual property rights owned by Skills Ontario. You agree that You will not modify, remove, alter, publish, transmit, sell, license, reproduce, recreate, create derivative works from or based upon, reverse engineer, upload, link, distribute any part of the App, or its content, information or services. </p>

                <h1>Termination</h1>
                <p>At its sole discretion, Skills Ontario may modify or discontinue the App, or may modify, suspend or terminate your access or use of the App, for any reason, with or without notice to You, and without liability to You or any third party. In addition to modifying, suspending or terminating Your access or use of the App, Skills Ontario reserves the right to take appropriate legal action, including, without limitation, pursuing civil, criminal or injunctive relief. Even after Your right to access or use the App is terminated, this Agreement shall remain in enforceable against You.</p>
                <p>You may terminate this Agreement by ceasing all use of the App and its content or services,  deleting your profile, and uninstalling the App, which will also delete all of your personal information on the App. Deleting your account is permanent and will remove all content, including bookmarked profiles, scheduled events and profile settings. Skills Ontario will not be liable to You or any third party for any compensation, indemnity or damages of any nature of kind whatsoever arising out of or in connection with the suspension or termination of this Agreement in accordance with its terms. Any suspension of this Agreement will be without prejudice to any other right or remedy Skills Ontario may have, now or in the future. </p>

                <h1>General</h1>
                <div class="sub-policy">
                    <h3>Governing Law</h3>
                    <p>Except where prohibited by applicable law, this Agreement is governed by the laws of Ontario and the federal laws of Canada applicable therein and You attorn to the jurisdiction of the courts of Ontario.</p>

                    <h3>Survival</h3>
                    <p>Sections 6,7,8, 9 and 10(e) survive the termination of this Agreement for any reason. </p>

                    <h3>Severability</h3>
                    <p>Except as otherwise provided in this Agreement, if any provision of this Agreement is found to be invalid or unenforceable, the invalidity or unenforceability of such provision shall not affect the validity of the remaining provisions of this Agreement, which shall remain in full force and effect. </p>

                    <h3>Waiver </h3>
                    <p>Except as otherwise provided in this Agreement, no waiver of any term of this Agreement shall be deemed a further or continuing waiver of such term or any other term and Skills Ontario’s failure to assert any right or provision under this Agreement shall not constitute a waiver of such right or provision. </p>

                    <h3>Transfer and Assignment</h3>
                    <p>This Agreement, and any rights and licenses granted hereunder may not be transferred or assigned by You but may be assigned by Skills Ontario without restriction.  Any attempted transfer in contravention of this provision shall be null and void and of no force and effect. </p>

                    <h3>Independent Legal Advice</h3>
                    <p>You acknowledge that You have been given the opportunity to obtain independent legal advice and have either obtained such advice or waived the need for such advice. You hereby represent and warrant that you have voluntarily accepted the terms and conditions of this Agreement. </p>

                    <h3>Entire Agreement</h3>
                    <p>This Agreement constitutes the entire agreement of the parties with respect to the subject matter herein and supersedes all prior agreements (including, but not limited to, any prior versions of this Agreement) or discussions between the parties with respect to such subject matter. </p>

                </div>
                <h1>Contact Information</h1>
                <p>Skills Ontario welcomes any questions, comments or inquiries You may have. You may contact us by email at info@skillsontario.com or in writing to:</p>
                <p>Privacy Officer<br>
                    Skills Ontario<br>
                    7A-60 Northland Rd.<br>
                    Waterloo, ON<br>
                    N2V 2B8</p>
            </div>
        </div>
    </div>
</div>