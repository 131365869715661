import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";

@Injectable()

export class UserService {

    url:any = environment.API_URL;

    constructor(
        private http : HttpClient
    ){

    }

    getUserList(data:any){
        return this.http.post(this.url + 'user/list', data);
    }

    updateUser(data:any){
        return this.http.patch(this.url + 'user/update', data);
    }
}